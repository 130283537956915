<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?$l('project.edit','编辑报修'):$l('project.add','新增报修')"
    width="900px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('project.labelWidth','80px')">
      <div class="vm-separate">
        <el-form-item :label="$l('project.name','项目名称')" prop="projectId">
          <vm-select-input :value="model.projectName" @select="$refs.projectSelect.open()"></vm-select-input>
        </el-form-item>
        <el-form-item :label="$l('project.name','梯号/内部名称')" prop="elevatorId">
          <vm-select-input :value="model.elevatorName" @select="$refs.elevatorSelect.open()"></vm-select-input>
        </el-form-item>
      </div>
      
      <div class="vm-separate">
        <el-form-item :label="$l('project.projectName','电梯停梯')" prop="name">
          <el-select v-model="model.elevatorStopped">
            <el-option :value="true" label="是"></el-option>
            <el-option :value="false" label="否"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$l('project.peopleTrapped','人员被困')" prop="name">
          <el-select v-model="model.peopleTrapped">
            <el-option :value="true" label="是"></el-option>
            <el-option :value="false" label="否"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$l('project.reporterName','报修人姓名')" prop="name">
          <el-input v-model="model.reporterName" :placeholder="$l('common.enter','请输入')"></el-input>
        </el-form-item>
        <el-form-item :label="$l('project.reporterPhone','报修人电话')" prop="name">
          <el-input v-model="model.reporterPhone" :placeholder="$l('common.enter','请输入')"></el-input>
        </el-form-item>
      </div>

      <div class="vm-separate">
        <el-form-item :label="$l('project.reportedTime','报修时间')" prop="name">
          <el-date-picker
            v-model="model.reportedTime"
            type="datetime"
            style="width: 100%"
            placeholder="选择日期"
            value-format="yyyy-MM-dd hh:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$l('project.reportRemark','报修备注')" prop="name">
          <el-input v-model="model.reportRemark" :placeholder="$l('common.enter','请输入')" type="textarea"></el-input>
        </el-form-item>
      </div>
    </el-form>

    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>
    <user-select ref="userSelectWb" @select="selectUserWb($event)"></user-select>
    <customer-select ref="customerSelect" @select="selectCustomer($event)"></customer-select>
    <elevator-select ref="elevatorSelect" @select="elevatorSelect"></elevator-select>
    <project-select ref="projectSelect" @select="projectSelect"></project-select>
  </el-dialog>
</template>
<script>
  import UserSelect from "@/views/user/UserSelect";
  import CustomerSelect from "@/views/customer/CustomerSelect";
  import ProjectSelect from "@/views/project/ProjectSelect";
  import ElevatorSelect from "@/views/elevator/ElevatorSelect";
  // import moment from "moment";
  export default {
    components: {UserSelect, CustomerSelect,ProjectSelect,ElevatorSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          id: 0,
          name: "",
          customerId: "",
          customerName: "",
          wbManagerId: "",
          wbManagerName: "",
          status: "",
          tasks:[],
          tasksMapping:[],
        },
      };
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`maintenance/repair-report/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = data;
          });
        }
      },
      selectCustomer(row) {
        this.model.customerId = row.id;
        this.model.customerName = row.name;
      },
      selectUserWb(row) {
        this.model.wbManagerId = row.id;
        this.model.wbManagerName = row.name;
      },

      elevatorSelect(row) {
        console.log(row,187);
        this.$set(this.model,"elevatorId",row.id);
        this.$set(this.model,"elevatorName",row.name);

      },
      projectSelect(row) {

        this.$set(this.model,"projectId",row.id);
        this.$set(this.model,"projectName",row.name);

      },

      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            const parmas = {...this.model};
            this.$http
              .save("maintenance/repair-report", parmas)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
      addLine() {
        this.model.tasks.push({
          key: this.$util.genKey(),
          "id": 0,
          "tenantId": 0,
          "planId": 0,
          "projectId": 0,
          "elevatorId": 0,
          "cycle": "",
          "planDate": "",
          "planWorkHour": 0,
          "assigned": true,
          "assigneeId": 0,
          "assignedTime": "",
          "maintainOrderId": 0,
          "actualStartTime": "",
          "actualEndTime": "",
          "actualWorkHour": 0,
          "projectName": "",
          "elevatorCode": "",
          "assigneeName": "",
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>