<template>
  <div class="project-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="/maintenance/repair-report/approve/page">
      <el-button slot="toolbar" type="primary" size="mini" @click="$refs.editPage.open(0)">{{$l("common.add", "新增")}}</el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('project.name','项目名称')">
            <el-input v-model.trim="filter.projectName" clearable></el-input>
          </vm-search>
          <vm-search label="电梯名称">
            <el-input v-model.trim="filter.elevatorCode" clearable></el-input>
          </vm-search>
          <vm-search label="电梯注册代码">
            <el-input v-model.trim="filter.regCode" clearable></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="maintenanceProjectName" :label="$l('project.name','项目名称')"></el-table-column>
      <el-table-column prop="elevatorName" label="梯号/内部名称"></el-table-column>
      <el-table-column prop="peopleTrapped" label="人员被困">
        <template slot-scope="scope">
          {{scope.row.peopleTrapped?"是":"否"}}
        </template>
      </el-table-column>
      <el-table-column prop="elevatorStopped" label="电梯停梯">
        <template slot-scope="scope">
          {{scope.row.elevatorStopped?"是":"否"}}
        </template>
      </el-table-column>
      <el-table-column prop="verified" label="是否已核实">
        <template slot-scope="scope">
          {{scope.row.verified?"是":"否"}}
        </template>
      </el-table-column>
      <el-table-column prop="verifierName" label="核实人姓名" align="center"></el-table-column>
      <el-table-column prop="cancelled" label="是否取消">
        <template slot-scope="scope">
          {{scope.row.cancelled?"是":"否"}}
        </template>
      </el-table-column>
      <el-table-column prop="cancellerName" label="取消人姓名" align="center"></el-table-column>
      <el-table-column prop="reporterName" label="报修人姓名" align="center"></el-table-column>
      <el-table-column prop="reporterPhone" label="报修人电话" align="center"></el-table-column>
      <el-table-column prop="reportedTime" label="报修时间" align="center"></el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="200">
        <template slot-scope="scope">
          <el-button type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>
          <el-dropdown trigger="click">
            <el-button type="primary">
              更多操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-circle-plus" command="verify" @click.native="$refs.verificationPage.open(scope.row.id,scope.row.reportRemark)">情况调查核实</el-dropdown-item>
              <el-dropdown-item icon="el-icon-circle-plus" command="cancel" @click.native="$refs.cancelPage.open(scope.row.id,scope.row.reportRemark)">情况核实取消</el-dropdown-item>
              <el-dropdown-item icon="el-icon-circle-plus" command="cancel" @click.native="toTask(scope.row)">转任务</el-dropdown-item>
              <el-dropdown-item type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "取消")}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <repair-verification ref="verificationPage" @save-success="getList(-1)"></repair-verification>
    <repair-cancel ref="cancelPage" @save-success="getList(-1)"></repair-cancel>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
  </div>
</template>
<script>
  import EditPage from "./RepairEdit.vue";
  import RepairVerification from "@/views/repair/RepairVerification";
  import RepairCancel from "@/views/repair/RepairCancel";

  export default {
    components: {EditPage,RepairVerification,RepairCancel},
    data() {
      return {
        filter: {
          name: "",
          customerName: "",
          wbManagerName: "",
          status: "",
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      toTask(row) {
        const arr = [];
        if (row.tasks instanceof Array) {
          row.tasks.forEach(item=>{
            arr.push(item.id);
          });
        }

        this.$http.post("/maintenance/repair-order/from-task",arr).then(()=>{
          this.$message.success("已转任务");
        });
      },
      deleteRow(row) {
        this.$confirm("确定删除该条记录吗?", this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/maintenance/repair-report/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success("该条记录" + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
